import { Trans } from 'next-i18next';
import React from 'react';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { PlcImg } from '@components/PlcImg/PlcImg.component';
import { Text } from '@components/Text/Text.component';
import { Breakpoint, useBreakpoint } from '@styles/breakpoints';
import { urlEnum } from '@utils/url';
import styles from './CASectionPopUp.module.scss';

interface Props {
  is15Days?: boolean;
}

const CASectionPopUp = ({ is15Days = false }: Props) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint < Breakpoint.s;

  return (
    <section className={`${styles["ca-sectionPopUp"]} plc-mt-l`}>
      <div className={styles["flex-logo"]}>
        <PlcImg alt="" plcSrc={{ src: 'ca-logo', type: 'local' }} width={isMobile ? 35 : 50} />
      </div>

      <div className={styles["flex-texte"]}>
        <Text flavour="light" i18nKey="landing.ca-title" variant={isMobile ? 'h5' : 'h4'} />
        <Text flavour="light" variant="p">
          <Trans i18nKey="landing.ca-content" ns="landing">
            <Text flavour="yellow" tag="span" variant="p" />
          </Trans>
        </Text>
        <BasicLink
          as={is15Days ? urlEnum.abonnementPro : urlEnum.proSavoirPlus}
          href={is15Days ? urlEnum.abonnementPro : urlEnum.proSavoirPlus}
          isUnderlined
          textProps={{
            flavour: 'light',
            i18nKey: 'common.pro.savoir.plus',
            variant: isMobile ? 'h5' : 'h4',
          }}
        />
      </div>
    </section>
  );
};

export { CASectionPopUp };
