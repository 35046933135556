import React from 'react';
import { Text } from '@components/Text/Text.component';
import { ApiStatusCode } from '@utils/api.utils';
import { defaultNamespaces } from '@utils/strings';
import { PlcNextPage, PlcNextPageContext } from 'features/business/app.types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

type ErrorProps = {
  errorCode?: number;
};
const Error: PlcNextPage<ErrorProps> = ({ errorCode }) => {
  const getErrorLabel = (code: number | undefined) => {
    const statusCode = code == null ? ApiStatusCode.notFound : code;

    switch (statusCode) {
      case ApiStatusCode.notFound:
        return 'common.error.404';
      case ApiStatusCode.unauthorized:
        return 'common.error.401';
      default:
        return 'common.error.generic';
    }
  };

  const getErrorCode = (code: number | undefined) => {
    const statusCode = code == null ? ApiStatusCode.notFound : code;

    switch (statusCode) {
      case ApiStatusCode.notFound:
      case ApiStatusCode.unauthorized:
        return statusCode;
      default:
        return null;
    }
  };

  return (
    <main className="error-page">
      <div className="container">
        {getErrorCode(errorCode) && (
          <Text tag="span" variant="h1">
            {getErrorCode(errorCode)}
          </Text>
        )}
        <Text
          // i18nKey={errorCode === ApiStatusCode.notFound ? 'common.error.404' : 'common.error.generic'}
          i18nKey={getErrorLabel(errorCode)}
          tag="h1"
          variant="h3"
        />
      </div>
    </main>
  );
};

export async function getServerSideProps(ctx: PlcNextPageContext) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale || 'fr', defaultNamespaces)),
    },
  };
}

export default Error;
