/* eslint-disable react/no-danger */
import {
  CACT_CATEGORIES,
  CACT_STATUS_ENUM,
  TagCommanderService,
} from 'features/tagCommander/business/TagCommander.service';
import Head from 'next/head';
import Router from 'next/router';
import { Article } from 'pleinchamp-api-client';
import React, { ComponentPropsWithRef, FC, PropsWithChildren, useEffect } from 'react';
import { Trans } from 'next-i18next';
import { BecomeProOverlay } from '@components/BecomeProOverlay/BecomeProOverlay.component';
import { isServer } from '@utils/server';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';

import styles from './ContentBody.module.scss';

interface Props extends ComponentPropsWithRef<'div'> {
  body: Article['body'];
  isPartial: boolean;
  isArticle?: boolean;
}

function getBecomeProBody() {
  return (
    <Trans i18nKey="actu.become-pro.pros" ns="actu">
      Continuez
      <a href={urlEnum.landingPro}>pro</a>
    </Trans>
  );
}

const ContentBody: FC<PropsWithChildren<Props>> = ({
  isArticle = false,
  body,
  isPartial,
  className,
  children,
  ...rest
}) => {
  const rootClass = classnames(
    styles['content-body'],
    isArticle ? styles.article : '',
    className,
    isPartial ? styles.partial : ''
  );

  const becomeProClassName = classnames(styles['content-body-become-pro'], className);

  useEffect(() => {
    if (isServer()) {
      return;
    }
    window.caReady = window.caReady || [];
    // eslint-disable-next-line func-names
    window.cact = function() {
      // eslint-disable-next-line prefer-rest-params
      window.caReady.push(arguments);
    };

    // Retriveves all the <iframe> in the DOM.
    const nodes = document.getElementsByTagName('iframe');

    const isAccepted = TagCommanderService.isCookieConsentByCategorieAccepted(CACT_CATEGORIES.PLC_CAT_PUBLICITAIRE);

    if (!isAccepted) {
      // FYI : The array length will vary because we remove 'iframe' tag.
      // So to do this without breaking "for loop" we iterate from the end because the indices will be preserved as you go backward.
      for (let i = nodes.length - 1; i >= 0; i -= 1) {
        const overlay = document.createElement('div');
        const currentFrame = nodes[i];
        const currentParent = currentFrame.parentElement;
        // is the iframe a youtube one ?
        const isYoutubeFrame =
          currentFrame !== null &&
          currentFrame.getAttribute('src') !== null &&
          currentFrame.getAttribute('src')?.includes('youtube');

        if (isYoutubeFrame) {
          const youtubeSrc = currentFrame?.getAttribute('src');
          const idYoutube = youtubeSrc !== null ? youtubeSrc.split('embed/')[1]?.split('?')[0] : '';
          const youtubeImgFromId = `https://img.youtube.com/vi/${idYoutube}/maxresdefault.jpg`;

          overlay.innerHTML = `<div class="iframe_overlay" id="dsg_player">
            <div data-idvideo="${idYoutube}" poster="${youtubeImgFromId}" style="background: url(&quot;${youtubeImgFromId}&quot;) center center / cover no-repeat;">
              <div aria-hidden="false" class="iframe_overlay_layer_cmp" style="display: block;">
                  <h2 class="iframe_overlay_title">Le visionnage de cette vidéo peut entraîner un dépôt de cookies par
                      l’opérateur de la plateforme vidéo</h3>
        
                  <p class="iframe_overlay_text">Pour poursuivre sa lecture, vous devez nous donner votre accord en
                      cliquant sur le bouton ci-dessous</p>
                  <button class="consentYoutubeCookies basic-button animated-button flat-button primary with-animation">Autoriser</button>
                  <a class="iframe_overlay_btn_secondaire" href="/protection-des-donnees" target="_PARENT">Consulter notre politique de gestion des
                      cookies</a>
              </div>
            </div>
          </div>`;

          currentParent?.replaceChild(overlay, currentFrame);
        }
      }

      // Adding event onClick on the buttons with the below id
      const buttons = document.getElementsByClassName('consentYoutubeCookies');
      if (buttons !== null) {
        for (let i = 0; i < buttons.length; i += 1) {
          buttons[i].addEventListener('click', () => {
            TagCommanderService.updateCookieConsentByCategories(
              CACT_CATEGORIES.PLC_CAT_PUBLICITAIRE,
              CACT_STATUS_ENUM.ON
            );
            Router.reload();
          });
        }
      }
    }
  }, []);

  return (
    <>
      {!isPartial && (
        <Head>
          <script async src="https://platform.twitter.com/widgets.js" />
        </Head>
      )}
      <div className={rootClass} {...rest}>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        {children}
      </div>
      {isPartial && (
        <BecomeProOverlay
          asOverlay={false}
          body={getBecomeProBody()}
          className={becomeProClassName}
          i18nTitle="actu.become-pro.title"
        />
      )}
    </>
  );
};

export { ContentBody };
