/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Text } from '../Text/Text.component';
import React, { ComponentPropsWithRef, FC } from 'react';
import { useSelector } from 'react-redux';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { useClassNames } from '@styles/breakpoints';
import { userSelectors } from '@user/business/User.store';
import classnames from 'classnames';
import { BreadCrumbStep } from './BreadCrumb.types';

import styles from './BreadCrumb.module.scss';

export interface BreadCrumbProps extends ComponentPropsWithRef<'nav'> {
  steps: BreadCrumbStep[];
  customSpacing?: boolean;
}

const BreadCrumb: FC<BreadCrumbProps> = ({ steps, className, customSpacing, ...rest }) => {
  const getLabel = ({ label, notI18n }: BreadCrumbStep) =>
    notI18n ? (
      <Text tag="span" variant="tiny">
        {label}
      </Text>
    ) : (
      <Text i18nKey={label} tag="span" variant="tiny" />
    );

  const rootClassname = useClassNames({
    all: 'none',
    m: ['block', 'plc-pt-basis', customSpacing ? undefined : 'plc-mb-xl'],
  });

  const isSSO = useSelector(userSelectors.isSSO);
  if (isSSO) {
    return null;
  }

  return (
    <nav className={classnames(styles['bread-crumb'], rootClassname, className)} {...rest}>
      <ol className="flex align-center">
        {steps.map(s => (
          <li key={s.label} style={{ cursor: s.onClick ? 'pointer' : 'default' }}>
            {s.linkProps ? (
              <PlcAnchor linkProps={s.linkProps} onClick={s.onClick} onKeyPress={s.onClick}>
                {getLabel(s)}
              </PlcAnchor>
            ) : (
              <>{getLabel(s)}</>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { BreadCrumb };
