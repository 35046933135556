import { Text } from '../Text/Text.component';
import { Trans } from 'next-i18next';
import React, { ComponentPropsWithRef, FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useAuthModal } from '@auth/business/AuthModal.hook';
import { PrimaryButton, SecondaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { CASectionPopUp } from '@landingPagePro/components/CASectionPopUp/CASectionPopUp.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { RootState } from '@store/store';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import { Constants } from '../../../utils/Constants';

import styles from './BecomeProOverlay.module.scss';

export interface BecomeProOverlayProps extends ComponentPropsWithRef<'div'> {
  i18nTitle: string;
  body?: ReactNode;
  asOverlay?: boolean;
  isCondensed?: boolean;
}

function getBecomeProDefaultBody() {
  return (
    <Trans i18nKey="common.pro.become-pro.body">
      <a href={urlEnum.landingPro}>Discover</a>
      mois gratuit
    </Trans>
  );
}
const BecomeProOverlay: FC<BecomeProOverlayProps> = ({
  i18nTitle,
  body,
  asOverlay = true,
  isCondensed = true,
  className,
  ...rest
}) => {
  const { showSignInModal } = useAuthModal();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const rootClassName = classnames(
    styles['become-pro'], isCondensed ? styles['condensed'] :'', asOverlay ? styles['overlay-message'] : '',
    className
  );

  return (
    <div className={rootClassName} {...rest}>
      <Text alignment="center" i18nKey={i18nTitle} tag="p" variant="h4" />
      <div className={styles["specific-buttons"]}>
        {!isAuthenticated && (
          <SecondaryButton
            onClick={() => showSignInModal()}
            textProps={{
              alignment: 'center',
              i18nKey: 'actu.become-pro.signin',
            }}
          />
        )}
      </div>
      <CASectionPopUp />
      <Text alignment="center" tag="p" variant="p">
        {getBecomeProDefaultBody()}
      </Text>

      <div className={`${styles["buttons"]} ${styles["specific-padding"]} ${styles["center"]}`}>
        <PlcAnchor linkProps={{ href: urlEnum.signUpPro }}>
          <PrimaryButton
            isPro
            textProps={{
              alignment: 'center',
              i18nKey: 'actu.become-pro.subscribe',
              i18nOptions: { price: Constants.PRICE },
            }}
          />
        </PlcAnchor>
      </div>
    </div>
  );
};

export { BecomeProOverlay };
